.gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: 445px;
    grid-gap: 8px;
}

.gallery-item {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 3%;
}

// .swiper-pagination-bullet-active {
//     background-color: rgba(217, 0, 72, 1) !important;
// }

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.5s ease;
}

.gallery-item:hover img {
    transform: scale(1.05);
}

.gallery_1 {
    grid-template-areas:
        "img_0 img_0 img_0 img_0"
        "img_0 img_0 img_0 img_0";
}

.gallery_2 {
    grid-template-areas:
        "img_0 img_0 img_1 img_1"
        "img_0 img_0 img_1 img_1";
}

.gallery_3 {
    grid-template-areas:
        "img_0 img_0 img_1 img_1"
        "img_0 img_0 img_2 img_2";
}

.gallery_4 {
    grid-template-areas:
        "img_0 img_0 img_1 img_1"
        "img_0 img_0 img_2 img_3";
}

.gallery_5 {
    grid-template-areas:
        "img_0 img_0 img_1 img_2"
        "img_0 img_0 img_3 img_4";
}

.gallery_mobile {
    grid-template-areas:
        "img_0 img_0 img_0 img_0"
        "img_1 img_1 img_1 img_1";
}


@media only screen and (max-width: 600px) {
    .gallery_mobile {
        height: 300px;
    }

    .gallery-item {
        width: 100%;
        height: 250px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
