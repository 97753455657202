form {
  .title {
    width: 100%;
  }
}

.beds-and-baths {
  padding: 15px;

  label {
    legend {
      font-size: 15px;
      color: #2A2A33;
    }
  }
}
