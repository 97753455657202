.listing_item {
  min-width: 49%;
  list-style: none;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 2px 6px -1px;
}

@media only screen and (max-width: 600px) {
  .listing_item {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.listing_item:hover {
  list-style: none;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -1px,
    rgba(0, 0, 0, 0.3) 0px 4px 6px -1px;
  cursor: pointer;
}

.listing_item__content_box {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  height: 100%;
}

.listing_item__thumbnail {
  background-repeat: no-repeat;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.listing_item__thumbnail__keyword {
  background-color: #0a0a1499;
  padding: 2px 8px;
  color: #ffffff;
  font-size: 12px;
  border-radius: 10px;
  font-weight: 700;
  height: 22px;
}

.listing_item__info {
  border-radius: 0px 0px 5px 5px;
  padding: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1%;
  justify-content: center;

  h1 {
    font-family: "Open Sans", "Time New Roman", arial !important;
  }

  p {
    font-family: "Open Sans", "Time New Roman", arial !important;
    font-size: 14px;
  }

  .listing_item__info__details__address {
    display: flex;
  }
}

.listing_item__info > h1 {
  font-size: 20px;
  text-align: left;
  color: 2A2A33;
  font-weight: 600;
  line-height: 24px;
}

.listing_item__info__details {
  width: 270px;
  display: flex;
  font-size: 14px;
}

.listing_item__info__details__listing_by {
  font-size: 10px !important;
  font-weight: 400;
  color: #596b82;
}

.listing_item__info__details__bold_span {
  font-weight: 700;
}

.listing_item__info__details__light_span {
  color: gray;
}

.listing_item__info__details__address {
  margin-top: 5px;
  margin-bottom: 3px;
  // font-size: 10px;
}

.listing_item__info__details__building {
  // margin-top: 5px;
  // font-weight: 600;
  // font-size: 14px;
  margin-top: 5px;
  margin-bottom: 3px;
}

.listing_item__info__details__pchange {
  font-variation-settings: "slnt" 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-top: 5px;
}
