.price-listPriceDropDown {
  background-color: white;
  max-height: 220px;
  overflow-y: scroll;
  width: 146px;
  margin-top: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;

  li {
    list-style: none;
    padding: 10px;
    font-size: 16px;

    &:hover{
      background-color: #F4FAFF;
    }
  }
}
