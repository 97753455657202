.custom-dropdown .dropdown-button {
    background-color: lightblue;
    border: 1px solid darkblue;

    border-radius: 5px; // Slightly rounded edges
    padding: 8px 12px; // Adjust padding as needed for spacing inside the dropdown
    // font-size: 16px; // Adjust font size for better readability
    background-color: rgb(243, 250, 254);
    border-color: rgb(41, 92, 247);
    font-size: 14px;
    font-weight: 800;
    min-width: 115px;

    border-radius: 5px;
    padding: 8px 12px;
    // font-size: 16px;
    cursor: pointer;
    /* Additional styles */
  }

.custom-dropdown .dropdown-options {
    list-style: none;
    margin: 0;
    padding: 0;
    // border: 1px solid darkblue;
    border-radius: 5px;
    background-color: white;
    /* Additional styles */
  }

.custom-dropdown .dropdown-options li {
    padding: 10px;
    cursor: pointer;
    /* Styles for hover, focus, etc. */
  }

  .custom-dropdown {
    position: relative; /* This makes it the positioning context */
    /* Additional styles here */
  }

  .dropdown-button {
    width: 100%; /* Optional: ensures the button fills the container */
    /* Additional button styles here */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    gap: 10px;
    position: relative;
  }

  .dropdown-options {
    position: absolute;
    top: 100%; /* Positions the top of the options list right below the button */
    left: 0;
    width: 100%; /* Optional: ensures the options list matches the button's width */
    background: white; /* Recommended: ensures options are readable */
    border: 1px solid #ccc; /* Optional: adds a border similar to native select */
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Optional: adds a small shadow for depth */
    z-index: 1000; /* Ensures the dropdown appears on top of other content */
    /* Additional options list styles here */
  }

  .dropdown-options li {
    padding: 10px; /* Adds some padding around each option for better touch targets */
    cursor: pointer; /* Indicates the options are interactive */
    /* Hover or focus styles here for visual feedback */
  }
