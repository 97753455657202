.authorized-user-container {
  position: relative;
  user-select: none;
}

.authorized-user {
  // width: 50px;
  height: 50px;
  cursor: pointer;
}

.authorized-user__dropdown {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  background-color: white;
  width: 200px;
  position: absolute;
  right: 0;
  margin-top: 15px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 6px;
  z-index: 2;
  cursor: pointer;
}

.authorized-user__dropdown::before {
  content: "";
  position: absolute;
  top: -10px;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: 2;
}

.authorized-user__dropdown::after {
  content: "";
  position: absolute;
  top: -10px;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 10px solid rgba(60, 64, 67, 0.10);
  z-index: 1;
}

.authorized-user__dropdown li {
  height: 44px;
  padding: 12px 16px;
  list-style: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #2a2a33;
}

.authorized-user__dropdown > li {
  height: 44px;
}

.authorized-user__dropdown > li:hover {
  background-color: rgb(243 250 254);
}

.auth-menunav-link > li:hover {
  background-color: rgb(243 250 254);
}

.authorized-user__dropdown > hr {
  border: 1px solid #e5e5e5;
  margin: 10px 0;
}
