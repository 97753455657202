.search-input-dropdown-wrapper {
  border-radius: 4px;
  border: 1px solid lightgrey;

  &:hover {
    border-radius: 3px;
  border: 1px solid rgb(0 0 0);
  }

  width: 350px;
}

.splash-focused-search {
  // box-shadow: 0 0 2px rgba(255, 255, 255, 0.9), /* inner white glow */
  // 0 0 5px rgb(0 0 0), /* major glow color */
  // 0 0 6px rgb(0 0 0), /* major glow color */
}

.splash-search-container {
  display: flex;
  border-radius: 3px;

  input {
    border-radius: 3px 0 0 3px;

    &:focus {
      outline: none;
    }
  }

  .search_container__search_button {
    background-color: rgb(246 246 250);
    padding: 0px 20px 0px 20px;
    border-radius: 0px 6px 6px 0px;

    svg {
      width: 22px;
      height: 22px;
      fill: rgb(64, 64, 64);
    }
  }
}

.search_container__search_button.modified {
  background-color: rgb(217, 0, 72);
  svg {
    fill: rgb(232, 232, 232);
  }
}

.search_container__search_button.modified:hover {
  svg {
    fill: rgb(255, 255, 255);
  }
}

.search_container__search_button:hover {
  cursor:  pointer;
  svg {
    fill: black;
  }
}

.splash-suggestions-dropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0px 0px 3px 3px;
  // border: 1px solid black;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;

  // Set styling rules of SuggestionItem for fetched suggestions
  ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
  background-color: rgb(250, 250, 250);
    z-index: 2;

    li {
      list-style-type: none;

      div {
        border-bottom: none;
        p {
          color: black;
          margin-left: 0;
        }
      }
    }

    li:last-child > div > p {
      border-bottom: none;
    }
  }
}

.fetched-suggestions-wrapper {
  .item-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    &:hover {
      background-color: rgb(44 104 246);
    }

    &:last-child:hover {
      border-radius: 0px 0px 3px 3px;
    }
  }
}


@media only screen and (max-width: 600px) {
  .search-input-dropdown-wrapper {
    width: 150px;
  }

}
