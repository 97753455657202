.profile-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .tabs-wrapper {
        // width: 1280px;
    }

    .chakra-tabs {
        width: 1000px;
    }

    .chakra-tabs__tab-panel {
        padding: 0px;
    }

    .content {
        // width: 1280px;

        .favourites-title {
            text-align: left;
            // font-size: 36px;
            // font-weight: 700;
            color: #2A2A33;
            font-weight: bold;
            font-size: 28px;
            line-height: 1.14;
        }

        .header-wrapper {
            display: flex;
            justify-content: space-between;
            padding: 30px 0px;
            padding-top: 0;

            .delete-home {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                p {
                    font-weight: 700;
                    color: rgb(44, 104, 246);
                }

                &:hover {
                    cursor: pointer;

                    p {
                        text-decoration: underline;
                    }
                }
            }
        }

        .favourites_wrapper {
            padding: 48px;

            .multi-price-graph-wrapper {
                display: flex;
                justify-content: center;
                padding-bottom: 50px;
            }
        }

        .listing-cards-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 20px;

        }
    }
}

@media only screen and (max-width: 600px) {
    .profile-wrapper {
        .tabs-wrapper {
            padding-left: 20px;
            padding-right: 20px;
        }

        .content {
            .favourites-title {
                font-size: 24px;
            }

            .header-wrapper {
                padding-left: 20px;
                padding-right: 20px;
            }

            .favourites_wrapper {
                padding-top: 24px;
                padding-bottom: 24px;
                padding-left: 0;
                padding-right: 0;
            }

            .listing-cards-wrapper {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}
