.apartments-list-title {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 20px;
  display: block;
  margin: 20px;
  text-transform: uppercase;
}

.search-results-container {
  display: flex;
  height: calc(100vh - 135px);
  justify-content: center;


  .left-component,
  .middle-component,
  .right-component {
    // flex: 1; /* Distributes space equally, adjust as needed */
    overflow: hidden;
    /* Prevents unwanted scrolling on non-scrollable components */
  }

  .left-component {
    overflow-y: auto;
    /* Enables vertical scrolling for the list */
    height: 100%;
    margin-top: 10px;
    flex-basis: 25%;
    min-width: 400px;
    // overflow-y: scroll;
  }

  .middle-component {
    /* Styling for the middle component, adjust padding/margins as needed */
    // background-color: aqua;
    flex-basis: 500px;
    padding: 30px;

    display: flex;
    flex-direction: column;
    /* Stack items vertically */
    align-items: center;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    text-align: center;
    /* Center the text inside the paragraph */
    height: 100%;
    /* Adjust height as necessary */
    overflow-y: hidden;
    /* Prevent scrolling */

    .slide-container-link {
      max-height: calc(100% - 170px);
    }
  }

  .search-unit-details-wrapper {
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .slide-apartment-image {
      max-width: 400px;
    }

    .price-plot-details {
      text-align: left;
      display: block;
    }

    .search-featured-img {
      height: 500px;
    }
  }

  .search-unit-details-wrapper img {
    max-width: 100%;
    /* Ensure the image is responsive and fits within the component */
    height: auto;
    /* Maintain aspect ratio */
    // margin-bottom: 20px;
    /* Add space between the image and the text */
    // max-width: 700px;
  }

  .right-component {
    // width: 100%; /* Ensures the map takes up the whole right section */
    // height: 100%; /* Adjust height as necessary to fill the container */
    // background-color: brown;
    flex-basis: calc(100% - 500px - 30%);
    height: 100vh;
    /* Adjust based on your layout */
    width: 100%;
    /* Adjust if you have a specific width */
  }
}


@media only screen and (max-width: 600px) {
  .search-results-container .left-component {
    min-width: 100%;
  }
}

.feedback-form-wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100%;
}
