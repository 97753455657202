@import "../../../mixins";

.home-container {
    display: flex;
    gap: 35px;
    margin-top: 10px;
}

.right-side-container {
    padding-left: 40px;

    .follow-buttons-wrapper {
        display: flex;
        flex-direction: column;
        border: 1px solid rgb(209, 209, 213);
        padding: 20px;
        border-radius: 11px;
        // margin-top: 20px;
        gap: 10px;
        width: 300px;
        max-width: 100%;
        position: relative;

        button {
            border-radius: 5px;
            font-weight: 800;
        }

        .book_tour_btn {
            border: 1px solid rgba(217, 0, 72, 1);
            background-color: rgba(217, 0, 72, 1);
            color: white;
            padding: 10px;
            z-index: 10;

            p {
                font-size: 12px;
                font-weight: 400;
            }

            &:hover {
                cursor: pointer;
                background-color: white;
                color: rgba(217, 0, 72, 1);
            }
        }

        .unavailable {
            border: 1px solid rgb(137 137 137);
            background-color: rgb(137 137 137);

            &:hover {
                color: rgb(93 93 93);
            }
        }

        .building_website_btn {
            border: 1px solid black;
            color: black;
            padding: 10px;
            font-size: 14px;
            font-size: 16px;
            z-index: 5;

            &:hover {
                cursor: pointer;
                border: 1px solid #0171ba;
                color: #0171ba;
            }
        }

        .booking_popup {
            position: absolute;
            bottom: -140px;
            left: 0px;
            padding: 15px;
            transform: unset;
            width: 300px;
        }

        .intro_popup {
            animation: slideIn 0.5s forwards;
        }

        .exit_popup {
            animation: slideOut 0.5s forwards;
        }

        @keyframes slideOut {
            from {
                left: 0px;
            }

            to {
                left: calc(100% + 300px);
            }
        }

        @keyframes slideIn {
            from {
                left: 100%;
            }

            to {
                left: 0px;
            }
        }

        .cat-container {
            position: relative;
            display: inline-block;
        }

        .cat-emoji {
            position: absolute;
            // left: 50%;
            // transform: translateY(0px);
            opacity: 0;
            // transition: transform 0.5s;
            font-size: 60px;
            // transform: rotate(-15deg);
            left: 75px;
            top: -86px;
            z-index: 0;
            width: 150px;
        }

        .slide-in {
            // transform: translateY(-20px); /* Adjust Y-axis as needed */
            opacity: 1;
            z-index: 11;
        }

        .slide-out {
            transform: translateY(20px);
            /* Adjust Y-axis as needed */
            // transition: transform 1s;
            z-index: 0;
            opacity: 0;
        }
    }
}

.left-pane {
    // width: 71vw;
    height: 100vh;
    padding-bottom: 20px;
    width: 100%;

    .show-page-map-container {
        height: 318px;
        width: 98%;
        border-radius: 5px;
        overflow: hidden;
        margin-top: 30px;
        margin-left: 10px;
    }
}

.listing-details-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    padding-bottom: 0;

    p {
        span {
            font-weight: 800;
        }
    }
}

.top-container {
    .show-address {
        font-size: 14px;
        font-family: 'Open Sans';
        color: #2A2A33;
    }

    .show-availability {
        // font-size: 14px;
        font-family: 'Open Sans';
        color: #2A2A33;
        text-transform: capitalize;
        font-weight: 600;
    }

    display: flex;
    /* Enable Flexbox */
    justify-content: space-between;
    /* Space between items, pushing one to the left and one to the right */

    .left-component {
        flex-grow: 1;
    }

    .right-component {}
}

.est-payment-container {
    display: flex;
    font-family: "Open Sans";
    font-size: 14px;
    font-size: 16px;

    .est-payment {
        background-color: #E0EFFE;
        margin: 15px 0px;
        padding: 6px 16px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
        font-weight: 600;

        span {
            font-weight: 800;
        }

        .info-link {
            color: #1E3A89;
            margin-left: 20px;
        }
    }

}

.jared-score-more-info {
    padding: 6px 16px;
    margin-bottom: 10px;

    .wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        justify-content: center;
        align-items: flex-start;

        .main-label {
            font-weight: 600;
            margin-bottom: 5px;
        }

        .bullet {
            margin-left: 20px;
        }

        .bullet.featured {
            font-weight: 600;
        }
    }

}

.jscore_dollars {
    font-weight: 800;
}

.top-container__header {
    @include flexCenter();
    justify-content: flex-start;
    gap: 20px;

    .right-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        width: 100%;

        h1 {
            font-size: 36px;
            font-family: "Open Sans";
        }
    }

    .left-header {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        // width: 100%;
        height: 100%;
        gap: 20px;

        p {
            font-size: 16px;
            font-family: 'Open Sans';
            display: flex;
            flex-direction: column;
            font-size: 20px;
            font-family: "Open Sans";

            span {
                font-weight: 800;
                font-size: 32px;
                font-family: 'Open Sans';
            }
        }
    }

    div {
        display: flex;
    }
}

.listing-info__header-menu {
    .details {
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 10px;
        // border-bottom: 1px solid rgb(209, 209, 213);
        padding-bottom: 15px;
        padding-top: 5px;
        justify-content: flex-start;
        align-items: center;
        align-content: center;


        li {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            background-color: #F5F5F9;
            padding: 12px;
            border-radius: 4px;
            font-size: 16px;
        }

        .area-link {
            color: #0171ba;
            font-weight: 800;
            // font-size: 16px;
            font-family: "Open Sans";
            // text-decoration: underline;
        }
    }
}

.overview {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px 0px;

    h1 {
        text-align: start;
        font-size: 24px;
        margin: 10px 0px;
        font-family: "Open Sans";
    }

    div.keywords {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        p {
            background-color: #EFEFF2;
            border-radius: 5px;
            white-space: nowrap;
            padding: 2px 8px;
            font-weight: 800;
            text-transform: uppercase;
            font-size: 14px;
        }
    }

    p {
        span {
            font-weight: 800;
            font-size: 16px;
        }
    }

    .overview-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        button {
            color: #0d4990;
            font-weight: 700;
        }
    }
}

.line-footer {
    display: flex;
    gap: 10px;

    p {
        span {
            font-weight: 700;
            font-size: 16px;
        }
    }
}

h1 {
    font-family: "Open Sans";
    font-size: 100px;
}

.right-header {
    .price-plot-title {
        font-size: 32px !important;
        text-align: left;
    }

    .price-plot-price-container {
        margin-bottom: 0 !important;
    }
}

.listing-price-graph-wrapper {
    // margin-top: 10px;
}

span.home-subtitle {
    margin-top: 10px;
    margin-bottom: 20px;
    display: inline-block;
    color: inherit;
    font-variation-settings: "slnt" 0;
    font-size: 20px;
    line-height: 30px;
    font-weight: 650;
    font-feature-settings: "pnum", "lnum", "case", "zero", "cv01", "cv08", "cv09";
    text-transform: uppercase;
}

.scroll-container {
    overflow: hidden;
    /* Hide scrollbar by default */
}

/* Show the scrollbar on hover */
.scroll-container:hover {
    overflow-y: auto;
    /* Show vertical scrollbar on hover */
}

.scroll-container::-webkit-scrollbar {
    width: 12px;
}

.scroll-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.related-units-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;


    .left-component,
    .right-component {
        // flex: 1;
    }

    .left-component {
        // flex-basis: 50%;
        width: 550px;
        height: 400px;
        margin-right: 12px;
        // overflow-y: scroll;
    }

    .left-component:hover {
        margin-right: 0;
    }

    .right-component {
        // flex-basis: 50%;
        flex-grow: 1;
    }
}

@media only screen and (min-aspect-ratio: 1.5/1) and (max-height: 900px) {
    .search-unit-details-wrapper .listing-info {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .top-container {
        flex-direction: column;
    }

    .top-container__header {
        flex-direction: column;
    }

    .top-container__header div {
        width: 100%;
    }

    .top-container__header .left-header {
        justify-content: flex-start;
    }

    .listing-info__header-menu {
        display: flex;
        justify-content: center;
    }

    // .listing-info__header-menu .details {
    //     margin-left: -10px;
    // }

    .right-side-container {
        padding-left: 0px;
        margin-left: 0px;
        display: flex;
        justify-content: center;
    }

    .est-payment-container {
        justify-content: center;
    }

    .related-units-container {
        flex-direction: column;
        padding: 0;
    }

    .related-units-container {

        .left-component {
            margin-left: 0;
            width: 100%;
        }

        .left-component .apartment-row {
            padding: 0;
            padding-bottom: 20px;
            padding-left: 10px;
        }

        .apartment-row:hover {
            padding-left: 10px;
            margin-left: -10px;
        }

        .right-component .home-subtitle {
            margin-top: 20px;
        }

        .right-component .js-plotly-plot {
            margin-left: -30px;
        }

        .right-component {
            overflow: hidden;
        }
    }

    .left-pane .show-page-map-container {
        margin-left: 0;
    }
}
