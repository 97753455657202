/* TODO Add site wide styles */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.cdnfonts.com/css/times-new-roman");

$primary-theme-blue-color: rgb(41 92 247);
$primary-btn-color-hover: rgb(30 59 138);
$primary-focus-color: rgb(44 104 246);

@mixin primary-btn($width, $height) {
  height: $height;
  width: $width;
  font-weight: 700;
  color: white;
  background-color: $primary-theme-blue-color;
  font-size: 16px;
  border-radius: 4px;

  &:hover {
    background-color: $primary-btn-color-hover;
  }
}

* {
  font-family: "Open Sans", sans-serif;
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;

  h1 {
    font-size: 20px;
    color: #2a2a33;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    --webkit-font-smoothing: antialiased;
    --webkit-tap-highlight-color: transparent;
    font-family: sans-serif;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.App {
  width: 90%;
  max-width: 700px;
  margin: 2em auto;
}

.bttn {
  background-color: #0061ff;
  min-height: 44px;
  min-width: 44px;
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 700;
}

@mixin primary-input() {
  width: 100%;
  padding: 9px 16px !important;
  margin-top: 5px !important;
  border-radius: 4px !important;
  border: 1px solid rgb(209, 209, 213) !important;
  background-color: rgb(246, 246, 250) !important;
  font-size: 16px !important;
  outline: none !important;
  height: 44px !important;

  &:focus {
    box-shadow: rgb(255 255 255) 0px 0px 0px 1px,
      rgb(166 229 255) 0px 0px 2px 3px, rgb(0 0 0) 0px 0px 2px 4px;
  }

  &:hover {
    border-color: #006aff !important;
  }
}
