.user-profile-page-content {
    padding: 48px;

    .title {
        font-weight: bold;
        font-size: 28px;
        line-height: 1.14;
    }

    .grid-container {
        display: flex;
        margin-left: -8px;
        margin-right: -8px;
        margin-top: -16px;
        flex-wrap: wrap;

        .outer-wrapper {
            border-style: solid;
            border-color: transparent;
            border-width: 16px 8px 0px;

            width: 100%;
            -webkit-box-flex: 0;
            flex: 0 0 auto;
            display: block;
        }

        .form-padding {
            max-width: 537px;
            padding: 16px 0px;
        }

        .grid-cell-wrapper {
            display: flex;
            margin-left: -8px;
            margin-right: -8px;
            margin-top: -16px;
            flex-wrap: wrap;
        }

        .field-wrapper {
            border-style: solid;
            border-color: transparent;
            border-width: 16px 8px 0px;

            width: 100%;
            -webkit-box-flex: 0;
            flex: 0 0 auto;
            display: block;
        }

        .edit-button {
            margin-left: 24px;
            cursor: pointer;
            display: inline-block;
            transition: box-shadow 0.1s ease 0s, border-color 0.1s ease 0s;
            outline: none;

            .edit-hightlight {
                text-decoration: none;
                color: rgb(0, 120, 130);
            }
        }


        .other-fields-container {
            padding: 24px 0px 0px;
            display: flex;
            margin-left: -8px;
            margin-right: -8px;
            margin-top: -16px;
            flex-wrap: wrap;

            .labeled-input {
                position: relative;

                .mini-label {
                    text-transform: capitalize;
                    color: rgb(107, 119, 128);
                    font-size: 14px;
                    line-height: 1.43;
                    padding-bottom: 8px;
                    text-align: left;
                    width: 100%;
                    display: inline-block;
                    margin-top: 16px;
                }

                .text-input {
                    border-radius: 8px;
                    border: 1px solid rgb(134, 144, 153);
                    transition: box-shadow 0.1s ease 0s, border-color 0.1s ease 0s;
                    padding: 8px;
                    font-size: 16px;
                    line-height: 1.5;
                    width: 100%;
                    display: inline-block;
                    outline: none;
                }

                .error-message {
                    color: rgb(181 51 68);
                    font-size: 14px;
                    text-align: left;
                    width: 100%;
                    margin-top: 5px;
                    font-style: italic;
                }
            }
        }

        .email-notification-preferences {
            padding-top: 45px;
            padding-bottom: 20px;
        }

        .preferences label {
            display: block;
            /* This makes each label start on a new line */
            margin-top: 8px;
            /* Adds some space between the checkboxes for better readability */
        }

        .preferences input[type="checkbox"] {
            margin-right: 8px;
            /* Adds some space between the checkboxes and the labels */
        }

        .submit-button[disabled] {
            color: rgb(205, 209, 212);
            background-color: rgb(245, 246, 247);
            border-color: rgb(205, 209, 212);
            box-shadow: none;

            cursor: none;
            pointer-events: none;
        }

        .submit-button {
            margin: 0px;
            border-radius: 8px;
            border-width: 1px;
            border-style: solid;
            cursor: pointer;
            display: inline-block;
            color: rgb(59, 65, 68);
            text-align: center;
            font-weight: bold;
            transition: top 0.1s ease 0s, box-shadow 0.1s ease 0s, border-color 0.1s ease 0s, background-color 0.1s ease 0s, color 0.1s ease 0s;
            white-space: nowrap;
            text-decoration: none;
            font-size: 16px;
            line-height: 1.5;
            padding: 8px 16px;
            outline: none;

            color: rgb(255, 255, 255);
            background-color: rgb(0, 120, 130);
            border-color: transparent;
            box-shadow: rgba(59, 65, 68, 0.7) 0px 8px 20px -15px;
            margin-bottom: 16px;
            outline: none;

            margin-top: 24px;
            padding-left: 64px;
            padding-right: 64px;
        }

        .submit-button:hover {
            color: rgb(0, 120, 130);
            background-color: rgb(255, 255, 255);
            border-color: rgb(0, 120, 130);
        }

        input:focus-visible {
            box-shadow: rgb(255, 255, 255) 0px 0px 0px 1px, rgb(77, 197, 207) 0px 0px 2px 3px, rgb(0, 120, 130) 0px 0px 2px 4px;
        }
    }

}


@media only screen and (max-width: 600px) {
    .user-profile-page-content.booking-modal {
        padding: 0 !important;
    }

    .user-profile-page-content .grid-container {
        margin-left: 0;
        margin-right: 0;
    }

    .booking-modal .row-container .left {
        flex-basis: 30% !important;
    }

    .user-profile-page-content .grid-container .other-fields-container .labeled-input .mini-label {
        padding-bottom: 0;
        margin-top: 0;
    }
}
