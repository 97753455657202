@import "../../mixins.scss";

.your-right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0px 50px;

    a {
        font-size: 15px;
        font-weight: 300;
        color: #006AFF;
    }
}

.region-footer-links {
    padding: 31px 24px;
}

.footer {
    padding: 30px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.four-links {
    @include flexCenter();
    list-style: none;
    gap: 5vw;


    button {
        font-weight: 700;

        &:hover {
            text-decoration: underline;
            font-weight: 700;
        }
    }

    li {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1vw;
        height: 30px;


    }

    .four-links__link::after {
        content: "";
        background-color: rgb(167 166 171);
        margin-left: 70px;
        height: 32px;
        width: 1px;
    }
}

.language-links {
    @include flexCenter();
    gap: 50px;
    list-style: none;
    color: #2A2A37;
    font-size: 14px;
    line-height: 24px;
    height: 30px;

    a:hover {
        text-decoration: underline;
    }
}


.under-footer {
    @include flexCenter();
    padding: 24px 0;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    p {
        width: 720px;
        font-size: 10px;
        color: #54545A;
        text-align: center;
    }
}


.root-0-1-693 {
    width: 100%;
    margin-top: 56px;
    padding-top: 48px;
    padding-bottom: 48px;
    background-color: rgba(0, 0, 0, 0.1);
}

.content-0-1-694 {
    display: flex;
    max-width: min(100% - 48px, 1000px);
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: center;
}

.gridContainer-0-1-696 {
    gap: 24px;
    display: flex;
    justify-content: space-between;
}

.root-0-1-698 {
    border: none;
    align-self: stretch;
    flex-shrink: 0;
}

// .gridSection-0-1-697 {
//     gap: 16px;
//     display: flex;
//     flex-direction: column;

//     a, a:active, a:focus, a:hover, a:link, a:visited {
//         text-decoration: none;
//     }
// }

.footerItemText-0-1-695 {
    cursor: pointer;
    padding-top: 6px;
    overflow-wrap: break-word;
    padding-bottom: 6px;
}

// .footerItemText-0-1-695 {
//     cursor: pointer;
//     padding-top: 6px;
//     overflow-wrap: break-word;
//     padding-bottom: 6px;
// }

.footer-text {
    color: rgba(0, 0, 0, 0.9);
    font-variation-settings: "slnt" 0;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}

.footer-title {
    color: rgba(0, 0, 0, 0.6);
    font-variation-settings: "slnt" 0;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.tag-line {
    margin-top: 40px;
}
