.price-plot {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.price-plot-title {
  color: inherit;
  font-variation-settings: "slnt" 0;
  font-size: 22px;
  line-height: 30px;
  font-weight: 650;
  font-feature-settings: "pnum", "lnum", "case", "zero", "cv01", "cv08", "cv09";
}

.price-plot-container {
  display: flex;
  margin-left: -24px;
  margin-right: -24px;

  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.price-plot-spacer {
  height: 4px;
  width: 4px;
  flex-shrink: 0;
}

.building-link {
  margin-right: 5px;
}

.building-link:hover {
  color: #0171ba;
}

.price-plot-separator {
  // min-height: 24px;
  // min-height: 10px;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   margin-bottom: 0px;
  //   padding-left: 24px;
  //   padding-right: 24px;
}

.price-plot-graph {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.plot-img-wrapper {
  overflow: hidden;
  position: relative;
  height: 250px;
}

.plot-img-wrapper img {
  width: 100%;
  /* Makes the image fill the container's width */
  position: absolute;
  top: -75px;
  /* Adjust this value to control the amount of crop from the top */
  left: -10px;
}

// .plot-img-wrapper > * {
//   width: 100%;
//   /* Makes the image fill the container's width */
//   position: absolute;
//   top: -75px;
//   /* Adjust this value to control the amount of crop from the top */
//   left: -10px;
// }

.price-plot-price-container {
  display: flex;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
}

.price-plot-price-container-row {
  font-family: var(--inter-font);
  display: flex;
  align-items: center;
  width: 100%;
}

.price-plot-price-text {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.price-plot-price-text span {}

.price-plot-price-text-wrapper {
  font-family: var(--inter-font);
  display: flex;
  align-items: center;
  gap: 4px;
  color: rgba(0, 0, 0, 0.9);
  min-height: 28px;
  white-space: nowrap;
}

.price-plot-price-title {
  color: inherit;
  font-variation-settings: "slnt" 0;
  font-size: 30px;
  line-height: 40px;
  font-weight: 650;
  font-feature-settings: "pnum", "lnum", "case", "zero", "cv01", "cv08", "cv09";
}

.price-plot-price-subtitle {
  color: inherit;
  font-variation-settings: "slnt" 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.price-plot-price-pchange {
  color: rgba(217, 0, 72, 1);
  font-variation-settings: "slnt" 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.price-plot-price-change {
  font-weight: 600;
}
