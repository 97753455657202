.square-foot-dropdown {
    // appearance: none; // Remove default browser styling
    // background-color: lightblue;
    // border: 1px solid darkblue;
    border-radius: 5px; // Slightly rounded edges
    padding: 8px 12px; // Adjust padding as needed for spacing inside the dropdown
    font-size: 16px; // Adjust font size for better readability
    background-color: rgb(243, 250, 254);
    border-color: rgb(41, 92, 247);
    font-size: 14px;
    font-weight: 800;
    min-width: 115px;
    // &:hover {
    //   border-color: #0056b3; // Darker blue on hover for a subtle interaction effect
    // }

    // &:focus {
    //   outline: none; // Remove the default focus outline
    //   box-shadow: 0 0 0 2px rgba(0, 86, 179, 0.5); // Custom focus style to improve accessibility
    // }

    option {
      background-color: white; // Ensures options are readable against the light blue background
      padding: 5px; // Padding for options
      font-size: 14px;
    }
  }
