.container {
    z-index: 11;
    position: relative;
    backdrop-filter: blur(4px);
    border-bottom-color: rgba(0, 0, 0, 0.05);
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

#navigation {
    height: 5vh;
    min-height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10%;

    width: 100%;
    margin: auto;
    display: flex;
    padding: 0;
    max-width: 1000px;
    box-sizing: border-box;
    min-height: 56px;
    align-items: center;
    justify-content: space-between;
}


.grid-item {

    a {
        &:hover {
            color: rgb(44 104 244);
        }
    }
}

#navigation {
    .left {
        padding: 0px 24px 0px 45px;
        font-weight: 400;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 16px;
        gap: 14px;

    }

    .right {
        padding: 0px 24px 0px 45px;
        font-weight: 400;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
        gap: 14px;
        -webkit-box-pack: end;
        justify-content: flex-end;
        flex: 1 0 auto;
    }
}

.search_container {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: 60% bottom;
    height: 408px;
    width: 100%;
    border-top: 2px solid #ebebeb;
    gap: 8%;
}

.search_container:nth-child(1) {}

.grid-item:nth-child(2) {}



.search_container>h1 {
    display: flex;
    justify-content: center;
    margin-top: 70px;
    width: 100%;
    color: #fff;
    line-height: 36px;
    filter: drop-shadow(0px 0px 5px #000);
    font-family: 'Times New Roman', sans-serif;
    font-size: 45px;
    font-weight: 500;
    line-height: 1.12;

    max-width: 550px;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
}

.logo {
    line-height: 1.12;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    font-family: sans-serif;
    font-size: 20px;
    text-size-adjust: 100%;
    color: #000;
    letter-spacing: 0.05em;
    font-weight: 800;
}

.logo:hover {
    color: #0171ba;
}

.logo-link:focus-visible {
    border: 0;
    outline: none;
}

.sublink-wrapper {
    display: block;
}

.sublink {
    font-size: 14px;
    font-weight: 600;
    color: #494949;
    padding: 10px 8px;
    border-radius: 5px;
}

.sublink:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.search_container__search_bar {
    // height: 70px;
    // padding: 22px 0px 24px 22px;
    // background-color: #F6F6FA;
    // width: 613px;
    width: calc(100% - 52px);
    max-width: 426px;
    color: rgba(0, 0, 0, 0.9);
    border: 0;
    height: 40px;
    font-size: 14px;
    transition: background-color 0.3s ease;
    font-weight: 400;
    line-height: 20px;
    padding-left: 20px;
    border-radius: 4px;
    outline-color: rgba(0, 0, 0, 0.1);
    outline-style: solid;
    outline-width: 1px;
    background-color: rgba(255, 255, 255, 1);
}


.search_container__search_button {
    display: flex;
    justify-content: center;
    align-items: center;
}


@media only screen and (max-width: 600px) {
    #navigation .left {
        gap: 10px;
        padding: 5px;
        padding-left: 10px;
    }

    #navigation .right {
        gap: 10px;
        padding: 5px;
        justify-content: flex-start;
    }

    #navigation {
        gap: 10px;
        padding: 10px;
    }

    .sublink-wrapper {
        display: none;
    }
}
