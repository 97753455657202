.about {
    h1 {
        font-family: "Capsule Sans Display", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
        font-size: 52px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -1.75px;
        line-height: 60px;
        -webkit-text-decoration: unset;
        text-decoration: unset;
        text-shadow: 0 0 10px rgb(255, 255, 255);

    }

    h2 {
        font-family: "Capsule Sans Display", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.24px;
        line-height: 36px;
        -webkit-text-decoration: unset;
        text-decoration: unset;
    }


    .header {
        position: relative;
        background-image: url('../assets/minimalist_buildings.PNG');
        background-size: cover;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        background-size: cover;
        background-position: center 30%;
        z-index: -1;
        max-width: 1000px;
        margin: auto;
    }

    .title::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.3);
        /* Adjust opacity as needed */
        z-index: -1;
    }

    .text-wrapper {
        margin-bottom: 60px;
        margin-top: 60px;
        text-align: center;
        display: block;
        // margin-left: 9px;
        // margin-right: 9px;
        visibility: visible;
        width: 594px;
    }

    .row {
        display: flex;
        justify-content: center;
    }

    .content {
        padding: 20px;
        text-align: center;
        max-width: 1000px;
        padding-top: 0px;
        margin: auto;
    }

    .centered-content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
    }

    .subtitle {
        margin-top: 20px;
        text-align: center;
    }

    .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 360px;
        max-height: 388px;
        flex-direction: column;
        width: 360px;
        height: 270px;
    }

    .image-wrapper {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 3%;
        position: relative;
    }

    .image-container img {
        // max-width: 100%;
        // height: auto;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 5%;
    }

    .img-label {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 2;
        padding: 5px;
        font-size: 18px;
        font-weight: 700;
        color: black;
    }
}
