.squares-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1px;
  font-weight: 700;
  font-size: 0.8rem;

  .bedsandbaths-square:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .bedsandbaths-square:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.bedsandbaths-square {
  outline: 1px solid #999;
  padding: 13px 19px;
  text-align: center;
  user-select: none;
  z-index: 1;

  &:hover {
    background-color: #f5f5f5;
  }
}

.selected-square {
  background-color: rgb(243 250 254);
  outline: 1px solid rgb(44 104 246);
  border: 1px double rgb(44 104 246);
  z-index: 2;
  padding: 12px 18px;

  &:hover {
    background-color: rgb(243 250 254);
  }
}
