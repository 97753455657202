.modal-container {
	width: 100vw;
	height: 100vh;
	position: fixed;
	// position: absolute;
	top: 0;
	left: 0;
	z-index: 15;
	// transform: translateZ(0);
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
	justify-content: center;
	align-items: center;
}
