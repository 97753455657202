.move-in-date-picker {
    width: 100%;
}

.react-datepicker-wrapper {
    display: block;
}

.booking-modal {

    .row-container {
        display: flex;
        justify-content: space-between;

        .left, .right {
            flex-basis: 50%;
        }

        .left {
            // width: 200px;
            // flex: 1 1;
        }

        .right {
            // flex-grow: 1;
            // flex: 1 1;
        }
    }
}

.grid-container.booking-modal-grid {
    width: 100%;
}

.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover, .react-datepicker__quarter-text--highlighted:hover, .react-datepicker__year-text--highlighted:hover {
    background-color: #3e6dda !important;
    color: white !important;
}

.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted {
    background-color: #3e6dda !important;
    color: white !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: white;
}
