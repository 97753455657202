.more-recommended-homes {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4%;
  color: #1F4494;
  font-weight: 600;
  font-family: 'Open Sans';
  font-size: 16px;
  margin: 16px 0;

  &:hover {
    text-decoration: underline;
  }
}

.explore-page {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  max-width: 100%;
  box-sizing: border-box;
  padding-top: 16px;
  flex-direction: column;
  padding-bottom: 16px;
}

@media only screen and (max-width: 600px) {
  .explore-page {
    padding-top: 8px;
  }
}
