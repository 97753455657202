.login-part-one {
    padding: 20px;

    .login-divider {
        overflow: hidden;
        text-align: center;
        color: rgb(107, 119, 128);
        padding: 0px 0px 16px;
        font-size: 16px;
        line-height: 1.1;
    }

    .login-divider::before, .login-divider::after {
        background-color: rgb(232, 233, 234);
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
    }

    .login-divider::before {
        right: 8px;
        margin-left: -50%;
    }

    .login-divider::after {
        left: 8px;
        margin-right: -50%;
    }

    .login-socials {
        .spacer {
            margin: 10px 0;
        }
    }

    .facebook-login-button::before {
        content: 'Facebook';
        font-weight: bold;
    }

    /* Smaller screens */
    @media (max-width: 600px) {
        .facebook-login-button::before {
            content: 'FB'; /* Shortened text for mobile */
        }
    }
}






.login_form {
    .login-input-wrapper {
        position: relative;

        label {
            // text-transform: capitalize;
            color: rgb(107, 119, 128);
            font-size: 14px;
            line-height: 1.43;
            padding-bottom: 8px;
            text-align: left;
            width: 100%;
            display: inline-block;
            margin-top: 16px;
        }

        input {
            border-radius: 8px;
            border: 1px solid rgb(134, 144, 153);
            transition: box-shadow 0.1s ease 0s, border-color 0.1s ease 0s;
            padding: 8px;
            font-size: 16px;
            line-height: 1.5;
            width: 100%;
            display: inline-block;
            outline: none;
        }

        input:focus-visible {
            box-shadow: rgb(255, 255, 255) 0px 0px 0px 1px, rgb(44 104 244) 0px 0px 2px 3px, rgb(37, 89, 210) 0px 0px 2px 4px;
        }
    }

    button {
        margin: 0px;
        border-radius: 8px;
        border-width: 1px;
        border-style: solid;
        cursor: pointer;
        display: inline-block;
        color: rgb(59, 65, 68);
        text-align: center;
        font-weight: bold;
        transition: top 0.1s ease 0s, box-shadow 0.1s ease 0s, border-color 0.1s ease 0s, background-color 0.1s ease 0s, color 0.1s ease 0s;
        white-space: nowrap;
        text-decoration: none;
        font-size: 16px;
        line-height: 1.5;
        padding: 8px 16px;
        width: 100%;
        outline: none;

        color: rgb(255, 255, 255);
        background-color: rgb(44 104 244);
        border-color: transparent;
        box-shadow: rgba(59, 65, 68, 0.7) 0px 8px 20px -15px;
        margin-bottom: 16px;
        outline: none;

        margin: 16px 0px;
    }

    button:hover {
        color: rgb(44 104 244);
        background-color: rgb(255, 255, 255);
        border-color: rgb(44 104 244);
    }

}
