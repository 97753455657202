.login-header {
    font-size: 25px;
    font-weight: 700;
    align-content: center;
    text-align: center;
    padding: 20px;
    padding-bottom: 0;
}

.login-part-two {
    padding: 20px;
}

@media only screen and (max-width: 600px) {
    .login-part-two {
        padding: 10px;
    }
}
