.apartment-row:hover {
  // box-shadow:
  // 0px 8px 15px rgba(0, 0, 0, 0.2),
  // 0px -8px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-left-color: rgb(217, 0, 72);
  // border-left-color: #0171ba;
  border-left-width: 2px;
}

.apartment-row.selected {
  border-left-color: rgb(217, 0, 72);
  border-left-width: 2px;
}

.apartment-row {
  padding: 20px;
  display: flex;
  /* This enables flexbox layout */
  align-items: center;
  /* This vertically centers the items if they have different heights */
  justify-content: space-between;
  /* This places space between the two elements */

  .unit-item-text-wrapper {
    flex: 1;
    flex-basis: 70%;
  }

  .spark-graph-wrapper {
    flex: 1;
    // padding-left: 10px;
    flex-basis: 35%;
  }

  .price-plot-title {
    font-size: 14px;
    font-weight: 500;
  }

  .price-plot-price-title {
    font-size: 16px;
    line-height: 30px;
    font-weight: 650;
  }

  .price-plot-price-container {
    margin-left: 24px;
    margin-right: 0;
    margin-bottom: 0;
  }

  .price-plot-details {
    margin-top: 10px;
    font-size: 14px;
  }

  .price-plot-price-text-wrapper {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .apartment-row .unit-item-text-wrapper {
    flex-basis: 65%;
  }
}
