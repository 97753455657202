.search_wrapper {
  width: 1600px
}

.index-page-container {
  width: 100%;
  height: calc(100vh - 135px);

  .index-page-content-container {
    width: 100%;
    flex-grow: 1;
    height: 100%;
  }
}

.search-component-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  padding: 0px 20px;
  cursor: pointer;
  gap: 15px;

  .focused-wrapper {
    margin-top: 10px;
    align-self: flex-start;
  }

  .search-input-wrapper {
    z-index: 1;

    .search-input {
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      width: 298px;
      max-width: 298px;
      height: 42px;
      border: 1px groove lightgray;
      border-radius: 4px;
      padding: 5px 0px 5px 8px;

      &:hover {
        border-color: #006aff !important;
      }


      .text-input {
        flex: 1;
        overflow: hidden;

        &:focus {
          outline: none;
        }
      }

    }

    .focused {
      border: none;
      box-shadow: rgb(255 255 255) 0px 0px 0px 1px,
        rgb(166 229 255) 0px 0px 2px 3px, rgb(0 0 0) 0px 0px 2px 4px;
      height: auto;
      z-index: 2;
    }

    .indexSearchDropdown {
      font-size: 16px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border-radius: 8px;
      position: absolute;
      min-width: 298px;
      /* Match the width of .search-input */
      width: auto;
      margin-top: 8px;
      background-color: #fff;
    }
  }
}


@media only screen and (max-width: 600px) {
  .search-component-wrapper {
    height: 150px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-evenly;
  }
}

@media only screen and (min-width: 2251px) {
  div {
    .index-page-content-container {
      display: grid;
      grid-template-columns: 3.01fr 1fr;
    }
  }
}

@media only screen and (max-width: 2250px) {
  div {
    .index-page-content-container {
      display: grid;
      grid-template-columns: 2fr 1fr;
    }
  }
}

@media only screen and (max-width: 1850px) {
  div {
    .index-page-content-container {
      display: grid;
      grid-template-columns: 2fr 1.3fr;
    }
  }
}

@media only screen and (max-width: 1550px) {
  div {
    .index-page-content-container {
      display: grid;
      grid-template-columns: 1.5fr 1.4fr;
    }
  }
}

@media only screen and (max-width: 1350px) {
  div {
    .index-page-content-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media only screen and (max-width: 1250px) {
  div {
    .index-page-content-container {
      display: grid;
      grid-template-columns: 1fr 2fr;
    }
  }
}

@media only screen and (max-width: 850px) {
  div {
    .index-page-content-container {
      display: grid;
      grid-template-columns: 0fr 1fr;
    }
  }
}
