.home-listing-type-wrapper {
  li {
    font-size: 14px;
  }

  .dropdown {
    background-color: white;
    z-index: 2;
    display: flex;
    flex-direction: column;
    border-radius: 0px 0px 5px 5px;
    position: absolute;
    margin-top: 7px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }

  .non-for-sale-dropdown {
    padding: 0px;
    margin: 0px;
  }
}
