.category-selector {
  width: calc(100% - 32px);
  height: 44px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  flex-direction: column;
  justify-content: center;
  color: rgba(0, 0, 0, 0.9);
}

.category-selector-wrapper {
  position: relative;
  max-width: 100%;

  overflow-x: auto;
  scrollbar-width: none;

  gap: 16px;
  width: 100%;
  display: inline-flex;
  flex-wrap: nowrap;

  // gap: 2px;
  display: flex;
  max-width: 100%;
  align-items: center;
  white-space: nowrap;

  justify-content: center;
}

.category-button {
  color: rgba(0, 0, 0, 0.9);
  background-color: transparent;
  padding: 10px 8px;
  min-height: 40px;
  border: none;
  cursor: pointer;
  height: fit-content;
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  font-family: var(--inter-font);
  border-radius: 4px;
  justify-content: center;
  white-space: nowrap;
}

.category-span {
  color: inherit;
  font-variation-settings: "slnt" 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.selected-span {
  color: rgba(217, 0, 72, 1);
}

.wrapper-splash {
  padding: 0 16px;
}

.slide-container {
  display: flex;
  margin-top: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 24px;
}

.slide-container-link {
  text-decoration: none;
  gap: 48px;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  display: flex;
  text-decoration: none;
}

.slide-apartment-image {
  gap: 16px;
  width: 100%;
  margin-bottom: 20px;
  // height: 300px;
  display: flex;
  max-width: 360px;
  flex-direction: column;

  border-top-left-radius: 25% 15%;
  border-top-right-radius: 25% 15%;
  overflow: hidden;
}

.wrapper-splash .slide-apartment-image {
  border-top-left-radius: 25% 25%;
  border-top-right-radius: 25% 25%;
}

@media only screen and (min-aspect-ratio: 1.5/1) and (max-height: 900px) {
  .slide-apartment-image {
    border-top-left-radius: 25% 25%;
    border-top-right-radius: 25% 25%;
  }
}

.slide-container {
  .slide-apartment-image {
    max-height: 388px;
  }
}

.slide-apartment-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* This prevents the image from stretching. */
  object-position: center;
  /* Adjusts the position of the image within the container */
  // border-radius: 5%;
}

.slide-apartment-image img:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -1px,
    rgba(0, 0, 0, 0.3) 0px 4px 6px -1px;
  cursor: pointer;
}

.price-plot {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.price-plot-title {
  color: inherit;
  font-variation-settings: "slnt" 0;
  font-size: 22px;
  line-height: 30px;
  font-weight: 650;
  font-feature-settings: "pnum", "lnum", "case", "zero", "cv01", "cv08", "cv09";
}

.price-plot-container {
  display: flex;
  margin-left: -24px;
  margin-right: -24px;

  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  // min-width: 340px;
}

.price-plot-spacer {
  height: 4px;
  width: 4px;
  flex-shrink: 0;
}

.price-plot-separator {
  // min-height: 24px;
  // min-height: 10px;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   margin-bottom: 0px;
  //   padding-left: 24px;
  //   padding-right: 24px;
}

.price-plot-graph {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.plot-img-wrapper {
  overflow: hidden;
  position: relative;
  height: 250px;
}

.plot-img-wrapper img {
  width: 100%;
  /* Makes the image fill the container's width */
  position: absolute;
  top: -75px;
  /* Adjust this value to control the amount of crop from the top */
  left: -10px;
}

// .plot-img-wrapper > * {
//   width: 100%;
//   /* Makes the image fill the container's width */
//   position: absolute;
//   top: -75px;
//   /* Adjust this value to control the amount of crop from the top */
//   left: -10px;
// }

.see-more-wrapper {
  gap: 16px;
  flex: 1;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}

.see-more-button {
  width: max-content;
  border: 1px solid transparent;
  cursor: pointer;
  height: 40px;
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 0;
  font-family: var(--inter-font);
  border-radius: 4px;

  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: right;

  height: 35px;
  // width: 120px;

  color: rgba(170, 0, 255, 1);
  background-color: rgba(170, 0, 255, 0.08);

  padding-left: 16px;
  padding-right: 16px;
  place-content: center;
}

.price-plot-price-container {
  display: flex;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
}

.price-plot-price-container-row {
  font-family: var(--inter-font);
  display: flex;
  align-items: center;
  width: 100%;
}

.price-plot-price-text {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.price-plot-price-text span {}

.price-plot-price-text-wrapper {
  font-family: var(--inter-font);
  display: flex;
  align-items: center;
  gap: 4px;
  color: rgba(0, 0, 0, 0.9);
  min-height: 28px;
}

.price-plot-price-title {
  color: inherit;
  font-variation-settings: "slnt" 0;
  font-size: 30px;
  line-height: 40px;
  font-weight: 650;
  font-feature-settings: "pnum", "lnum", "case", "zero", "cv01", "cv08", "cv09";
}

.price-plot-price-subtitle {
  color: inherit;
  font-variation-settings: "slnt" 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.price-plot-price-pchange {
  color: rgba(217, 0, 72, 1);
  font-variation-settings: "slnt" 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.price-plot-price-change {
  font-weight: 600;
}


@media only screen and (max-width: 600px) {

  .category-selector {
    width: 100%;
    height: 100%;
  }

  .slide-container {
    margin-top: 16px;
  }

  .slide-container-link {
    flex-direction: column;
    gap: 10px;
  }

  .slide-container .slide-apartment-image {
    height: 300px;
  }

  .category-selector-wrapper {
    position: relative;
    width: 100%;
    /* Ensures the wrapper extends full width */
    display: flex;
    /* Enables flexbox layout */
    flex-wrap: wrap;
    /* Allows items to wrap onto multiple lines */
    align-items: center;
    /* Centers items vertically */
    justify-content: center;
    /* Centers items horizontally on each line */
    gap: 0px;
    /* Maintains spacing between buttons */
    overflow-x: auto;
    /* Allows scrolling horizontally if needed, consider if this is necessary with wrapping */
    scrollbar-width: none;
    /* Hides scrollbar, ensure this is what you want */
    white-space: nowrap;
    padding-bottom: 8px;
  }

  .category-button {
    padding: 0px 5px;
    min-height: 30px;
  }
}
