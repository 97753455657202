.close-button-container {
  display: none;
}

.popup {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(64, 64, 64);
  color: white;
  padding: 10px;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 20;
  /* Ensure it's above other content */
}
