.blog_container {
    h1 {
        font-family: "Capsule Sans Display", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
        font-size: 52px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -1.75px;
        line-height: 60px;
        -webkit-text-decoration: unset;
        text-decoration: unset;
        text-shadow: 0 0 10px rgb(0 0 0 / 40%);
        color: white;
        text-align: left;
    }

    h2 {
        font-family: "Capsule Sans Display", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.26px;
        line-height: 36px;
        -webkit-text-decoration: unset;
        text-decoration: unset;
    }


    .header {
        position: relative;
        background-image: url('../assets/cat_graphics/cat_chicago_bean.webp');
        background-size: cover;
        height: 550px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-position: center 30%;
        max-width: 100%;
        margin: auto;

        h1 {
            font-family: Helvetica, Arial, sans-serif;
        }
    }

    .text-wrapper {
        // margin-bottom: 60px;
        margin-top: 60px;
        // text-align: center;
        display: block;
        // margin-left: 9px;
        // margin-right: 9px;
        visibility: visible;
        width: calc(70% - 18px);
        text-align: left;

        h1 {
            text-shadow: none;
            color: black;
            display: block;
            margin-bottom: 10px;
            font-size: 36px;
            text-align: left;
            font-weight: bold;
        }
    }

    .text-wrapper.img_subtext {
        margin-top: 15px;
        font-size: 18px;
    }

    .row {
        display: flex;
        justify-content: center;
    }

    .content {
        padding: 20px;
        text-align: center;
        max-width: 1000px;
        padding-top: 0px;
        margin: auto;
    }

    .centered-content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
    }

    .subtitle {
        margin-top: 20px;
        text-align: center;
    }

    .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        max-height: 500px;
        flex-direction: column;
        width: 360px;
        height: 500px;
    }

    .image-wrapper {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 3%;
        position: relative;
    }

    .image-container.full_row {
        width: 600px;
    }

    .image-container img {
        // max-width: 100%;
        // height: auto;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 3%;
        transition: transform .5s ease;
    }

    .image-container:hover img {
        transform: scale(1.05);
    }

    .img-label {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 2;
        padding: 5px;
        font-size: 20px;
        font-weight: 700;
        color: black;
    }

    .text-container {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        max-height: 500px;
        flex-direction: column;
        width: 390px;
        height: 500px;
        align-items: flex-start;
        justify-content: center;
        margin-left: 40px;
        margin-right: 40px;


        h1 {
            text-shadow: none;
            color: black;
            display: block;
            margin-bottom: 44px;
        }

        p {
            font-family: "Capsule Sans Text", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: normal;
            letter-spacing: -0.25px;
            line-height: 28px;
            -webkit-text-decoration: unset;
            text-decoration: unset;
            color: black;
            text-align: left;
        }
    }

    .spacer {
        padding: 20px;
    }

    .underline_link {
        text-decoration: underline;
        color: black;
    }

    @media only screen and (max-width: 600px) {
        .centered-content {
            display: block;
        }

        .image-container {
            margin-top: 20px;
            margin-left: auto;
            margin-right: auto;

        }

        .text-container {
            margin-left: auto;
            margin-right: auto;
            padding: 20px;
            max-height: 250px;
            max-height: fit-content;
            height: auto;
        }

        .text-wrapper {
            width: 90%
        }
    }
}
