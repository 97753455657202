.contact-margins {
  max-width: 1032px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.contact-content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  min-height: 100vh;
}

.contact-root {
  width: 100%;
  margin: auto;
  padding: 24px;
  max-width: 1152px;
  box-sizing: border-box;
  font-family: var(--inter-font);
  border-radius: 4px;
  margin-top: 50px;
}

.content-0-1-692 {
  display: flex;
  justify-content: space-between;
}

.text-0-1-693 {
  max-width: 446px;
}

.row-0-1-694 {
  color: rgba(0, 0, 0, 0.9);
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding-top: 16px;
  padding-bottom: 16px;
}

.interactive-0-1-695 {
  width: calc(100% + 24px);
  cursor: pointer;
  margin-left: -12px;
  margin-right: -12px;
  padding-left: 12px;
  border-radius: 4px;
  padding-right: 16px;
}

.css-10dohqv {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.line-0-1-697 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-row {
  display: flex;
  gap: 12px;
}
